<template>
  <div class="layout">
    <div
      v-if="pageName !== 'authorization' && pageName !== 'privacy-policy'"
      class="layout__menu"
    >
      <AppMenu />
    </div>
    <div class="layout__content">
      <AppHeader
        v-if="pageName !== 'authorization' && pageName !== 'privacy-policy'"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppMenu from "@/components/AppMenu.vue";

export default {
  name: "MainLayout",
  components: {
    AppHeader,
    AppMenu,
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
  },
  created() {
    if (this.pageName !== "privacy-policy") {
      if (!localStorage.getItem("alemtoken")) {
        this.$router.push("/");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  &__content {
    width: 100%;
  }
}
</style>
