import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/plugins/axios';
import "./assets/styles/main.scss";
import VueTheMask from 'vue-the-mask'

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(store).use(router)
    .use(VueTheMask)
    .mount('#app');