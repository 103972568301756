<template>
  <div class="header">
    <div class="container">
      <div class="header__inner">
        <h1 class="header__title">{{ $route.meta.title }}</h1>
        <!-- <form class="header__form">
          <input class="header__input" type="text" />
          <button class="header__search">
            <img src="@/assets/images/search.svg" alt="" />
          </button>
        </form> -->
      </div>
    </div>
  </div>
</template>
    
  <script>
export default {
  name: "AppHeader",
  mounted() {
    if (!this.$store.state.cities?.length) {
      this.getCities();
    }
    if (!this.$store.state.regions?.length) {
      this.getRegions();
    }
  },
  methods: {
    getCities() {
      this.$axios
        .get(`/cities`)
        .then((response) => {
          this.$store.dispatch("getCities", response.data);
        })
    },
    getRegions() {
      this.$axios
        .get(`/regions`)
        .then((response) => {
          this.$store.dispatch("getRegions", response.data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  },
};
</script>
    
    <style lang="scss">
.header {
  padding: 24px 0;
  background: #fff;
  box-shadow: 0px 4px 40px 0px #eee, 0px 4px 8px 0px rgba(66, 71, 76, 0.05),
    0px 0px 0.5px 0px rgba(66, 71, 76, 0.32);
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    color: #000;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 91.5%; /* 29.28px */
  }

  &__form {
    position: relative;
    max-width: 266px;
  }

  &__input {
    border-radius: 13px;
    border: 2px solid #d1d1d1;
    background: #fff;
    width: 100%;
    height: 50px;
    padding: 14px 45px 14px 15px;
    outline: none;
  }

  &__search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    cursor: pointer;
  }
}
</style>