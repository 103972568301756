import {
  createRouter,
  createWebHistory
} from 'vue-router'
import AuthorizationPage from '../views/AuthorizationPage.vue'
import MainLayout from "@/layouts/MainLayout.vue"
const routes = [{
    path: '/',
    component: MainLayout,
    children: [{
        path: '',
        name: 'authorization',
        component: AuthorizationPage
      },
      {
        path: '/main',
        name: 'main',
        meta: {
          title: "Главная"
        },
        component: () => import('../views/MainPage.vue'),
      },
      {
        path: '/cities',
        name: 'cities',
        meta: {
          title: "Города"
        },
        component: () => import('../views/CitiesPage.vue'),
      },
      {
        path: '/regions',
        name: 'regions',
        meta: {
          title: "Регионы"
        },
        component: () => import('../views/RegionsPage.vue'),
      },
      
      {
        path: '/goods',
        name: 'goods',
        meta: {
          title: "Товары"
        },
        component: () => import('../views/GoodsPage.vue'),
      },
      {
        path: '/delivery',
        name: 'delivery',
        meta: {
          title: "Доставщики"
        },
        component: () => import('../views/DeliveryPage.vue'),
      },
      {
        path: '/clients',
        name: 'clients',
        meta: {
          title: "Клиенты"
        },
        component: () => import('../views/ClientsPage.vue'),
      },
      {
        path: '/orders',
        name: 'orders',
        meta: {
          title: "Заказы"
        },
        component: () => import('../views/OrdersPage.vue'),
      },
      {
        path: '/adjustments',
        name: 'adjustments',
        meta: {
          title: "Корректировки"
        },
        component: () => import('../views/AdjustmentsPage.vue'),
      },
      {
        path: '/failures',
        name: 'failures',
        meta: {
          title: "Отказы"
        },
        component: () => import('../views/FailuresPage.vue'),
      },
      {
        path: '/push',
        name: 'push',
        meta: {
          title: "Пуш уведомление"
        },
        component: () => import('../views/PushPage.vue'),
      },
      {
        path: '/marketing',
        name: 'marketing',
        meta: {
          title: "Маркетинг"
        },
        component: () => import('../views/MarketingPage.vue'),
      },
      
      {
        path: '/support',
        name: 'support',
        meta: {
          title: "Поддержка"
        },
        component: () => import('../views/SupportPage.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'privacy-policy',
        meta: {
          title: "Политика конфиденциальности"
        },
        component: () => import('../views/PrivacyPolicy.vue'),
      },
     
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router