import {
  createStore
} from 'vuex'

export default createStore({
  state: {
    pageTitle: '',
    cities: [],
    regions: [],
    months: [{
        id: 1,
        title: "Январь"
      },
      {
        id: 2,
        title: "Февраль"
      },
      {
        id: 3,
        title: "Март"
      },
      {
        id: 4,
        title: "Апрель"
      },
      {
        id: 5,
        title: "Май"
      },
      {
        id: 6,
        title: "Июнь"
      },
      {
        id: 7,
        title: "Июль"
      },
      {
        id: 8,
        title: "Август"
      },
      {
        id: 9,
        title: "Сентябрь"
      },
      {
        id: 10,
        title: "Октябрь"
      },
      {
        id: 11,
        title: "Ноябрь"
      },
      {
        id: 12,
        title: "Декабрь"
      }
    ]
  },
  getters: {},
  mutations: {
    chagePageName(state, title) {
      state.pageTitle = title
    },
    addCities(state, cities) {
      state.cities = cities
    },
    addRegions(state, cities) {
      state.regions = cities
    }
  },
  actions: {
    getPageTitle({
      commit
    }, title) {
      commit('chagePageName', title)
    },
    getRegions({
      commit
    }, regions) {
      commit("addRegions", regions)
    },
    getCities({
      commit
    }, cities) {
      commit("addCities", cities)
    }
  },
  modules: {}
})