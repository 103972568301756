<template>
  <div class="authorization">
    <div class="authorization__inner">
      <img class="authorization__logo" src="@/assets/images/logo.png" alt="" />
      <div class="authorization__title">Авторизация</div>
      <form class="authorization__form">
        <input
          v-model="email"
          class="authorization__input"
          type="email"
          placeholder="Email"
        />
        <input
          v-model="password"
          class="authorization__input"
          type="password"
          placeholder="Пароль"
        />
        <p class="authorization__forgot">Забыли пароль?</p>
        <button @click.prevent="getToken" class="authorization__btn">
          Вход
        </button>
      </form>
      <p>{{ error }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthorizationPage",
  data() {
    return {
      email: null,
      password: null,
      error: null,
    };
  },
  mounted() {
    if(localStorage.getItem("alemtoken")) {
      this.$router.push("/main");
    }
  },
  methods: {
    getToken() {
      this.$axios
        .post("/login", {
          login: this.email,
          password: this.password,
        })
        .then((response) => {
          localStorage.setItem("alemtoken", response.data.accesstoken);
          location.reload()
        })
        .catch((error) => {
          console.error("Error:", error);
          this.error = error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.authorization {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  &__inner {
    max-width: 525px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0px 4px 40px 0px #eee, 0px 4px 8px 0px rgba(66, 71, 76, 0.05),
      0px 0px 0.5px 0px rgba(66, 71, 76, 0.32);
    padding: 24px;
    text-align: center;
  }

  &__logo {
    margin: 0 auto 40px;
  }

  &__title {
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 91.5%;
    margin-bottom: 40px;
  }

  &__input {
    border-radius: 13px;
    border: 1px solid #bdbdbd;
    background: #fff;
    width: 100%;
    padding: 14px 16px;
    & + & {
      margin-top: 16px;
    }
  }

  &__forgot {
    color: #32a7fc;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    margin-top: 40px;
  }

  &__btn {
    border-radius: 13px;
    background: #32a7fc;
    width: 155px;
    padding: 16px;
    margin-top: 40px;
    color: #fff;
  }
}
</style>